@import '../components/variables';

.dashboardPage {
  display: flex;
  flex-direction: column;
  gap: 2em;
  min-height: 100vh;
}

.dashboardPage_listStats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}

.dashboardPage_content {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 2em;

  @media screen and (max-width: $tablets) {
    grid-template-columns: 1fr;
  }
}

.dashboardPage_content_col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2em;
}

.dashboardPage_featuredProperty {
  display: flex;
  flex-direction: column;
  gap: 10px;
}