@import '../variables';

.parcelling_hero {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: $primary_dark;
  height: 35vw;
  min-height: 62vh;
  max-height: 78vh;

  @media screen and (max-width: $laptop_medium) {
    grid-template-columns: 1fr;
    max-height: unset;
    min-height: unset;
    grid-template-rows: 40vh 46vh;
    height: 86vh;
  }

  @media screen and (max-width: $tablets) {
    grid-template-rows: 26vh 59vh;
  }
}

.parcelling_hero_left {
  padding: 4em;
  display: flex;
  flex-direction: column;
  gap: 2em;
  justify-content: center;

  @media screen and (max-width: $laptop_medium) {
    padding: 2em;
    gap: 1em;
  }
}

.parcelling_hero_left_title {
  display: flex;
  align-items: center;
  gap: 10px;

  span {
    font-size: 30px;
    font-weight: 900;

    .span_small {
      font-weight: normal;
      color: $gray;
    }
  }

  @media screen and (max-width: $tablets){
    flex-direction: column;
    align-items: unset;

    span {
      font-size: 26px;
    }
  }
}

.parcelling_hero_arrow_content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.parcelling_hero_arrow {
  background-color: $primary;
  width: 48px;
  border-radius: 4px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 14px;
  }

  @media screen and (max-width: $mobile) {
    width: 30px;
    height: 30px;

    svg {
      width: 10px;
    }
  }
}

.parcelling_hero_right {
  width: 100%;
  height: 35vw;
  min-height: 62vh;
  max-height: 78vh;

  @media screen and (max-width: $laptop_medium) {
    grid-row: 1/2;
    height: 100%;
    min-height: unset;
    max-height: unset;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}