@import './variables';

.placesAndSectors {
  margin: 2em 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

// List
.placesAndSectors_list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2em;

  @media screen and (max-width: $tablets) and (min-width: $mobile) {
    gap: 1em;
  }

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr;
  }
}
.placesAndSectors_list_item {
  background-color: $grey;
  padding: 1.5em;
  border-radius: $radius;;
  display: flex;
  flex-direction: column;
  gap: 1em;
  text-decoration: none;
  color: $primary;

  @media screen and (max-width: $tablets) and (min-width: $mobile) {
    padding: 10px;
    img {
      height: 14em;
      object-fit: cover;
    }
  }

  img {
    width: 100%;
  }
}
.placesAndSectors_location {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    width: 10px;
  }
}

.placesAndSectors_location_first {
  display: flex;
  align-items: center;
  gap: 3px;
  font-weight: 600;

  svg {
    width: 25px;
  }
}