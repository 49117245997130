.checkBox {
  display: flex;
  gap: 0.5em;
  align-items: center;
  font-size: 14px;
  color: $primary;

  a {
    color: $primary;
    font-weight: 600;
    text-decoration: underline;
  }
}