@import './variables';

// colores de texto
.textColor_white {
  color: white;
}

.textColor_gray {
  color: $gray;
}

.textColor_blue_green {
  color: $blue_green;
}

.textColor_primary {
  color: $primary;
}

.textDecorateNone {
  text-decoration: none;
}

// Text Weight
.textBold {
  font-weight: 600;
}

// Alineación del text
.textCenter {
  text-align: center;
}

// colores de fondo
.bg_secundary {
  background-color: $primary_dark;
}

.bg_grey {
  background-color: $grey;
}


// Font text
.fontText_xl {
  font-size: 20px;
}

.fontText_lg {
  font-size: 18px;
}

.fontText_md {
  font-size: 16px;
}

.fontText_sm {
  font-size: 14px;
}

.fontText_xs {
  font-size: 12px;
}

// Margin
.mt5 {
  margin-top: 5em;
}

// Line
.textUnderline {
  text-decoration: underline;
}

// Pointer
.textPointer {
  cursor: pointer;
}

// Padding
.paddingRight_xs {
  padding-right: 70px !important;
}

.paddingRigth_sm {
  padding-right: 5px;
}

.zIndex-7 {
  z-index: 7;
}

.zIndex-6 {
  z-index: 6;
}

.zIndex-5 {
  z-index: 5;
}

// Display
.displayFlex {
  display: flex;
}

// Error
.inputCustom_error {
  font-size: 14px;
  color: #ff6666;
}

// Icons
.iconImage {
  padding: 8px;
  border-radius: 4px;
  width: 40px;
  height: 40px;

  svg {
    width: 100% !important;
  }
}