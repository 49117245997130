@import '../components/variables';

.dashboardLayout {
  padding: 2em 0;
  display: grid;
  grid-template-columns: 238px 1fr;
  gap: 2em;

  .container-filters {
    width: 50%;
  }

  .about-input {
    width: calc(50% - 16px);
    margin-bottom: 16px;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  @media screen and (max-width: $laptop_medium) {
    grid-template-columns: 1fr;

    .container-filters {
      width: 100%;
    }
    .about-input {
      width: 100%;
    }
  }
}