@import './variables';

.buttonGoogle {
  border: 1px solid $red;
  color: $red;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 35px;
  cursor: pointer;
  border-radius: $radius;
  width: 200px;

  //Add hover animation
  &:hover {
    border-radius: 0;
    transition: border 0.2s;
  }

  svg {
    width: 20px;
  }

  @media screen and (max-width: $mobile) {
    width: 100%;
  }
}