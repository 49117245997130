@import './variables';

.positionNavbar {
  position: sticky;
  top: 0px;
  transition: all .5s ease;
  background-color: transparent;
}

.header_absolute {
  position: absolute;
  width: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 75px;
}

.header_login_mobile {
  display: none;

  @media screen and (max-width: $mobile) {
    display: flex;
    flex-direction: row;
    gap: 2em;
    justify-content: center;
    padding: 9px 0;
  }
}

.header_navigation {
  display: flex;
  flex-direction: row;
  gap: 15px;

  .header_navigation_items {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
    position: relative;

    @media screen and (max-width: $tablets) {
      display: none;
    }

    &.header_navidation_active {
      font-weight: 900;

      &::before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        height: 4px;
        background-color: black;
        width: 100%;
      }
    }
  }

  .header_navigate_btn_login {
    @media screen and (max-width: $mobile) {
      display: none;
    }
  }

  .header_navigation_menu_mobile {
    display: none;

    @media screen and (max-width: $tablets) {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .header_navigation_mobile {
    display: none;

    @media screen and (max-width: $tablets) {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: white;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1.5em;
      display: none;

      &.activeMenuMobile {
        display: flex;
      }

      .header_navigate_close_menu {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;

        svg {
          width: 50px;
        }
      }

      .header_navigation_mobile_items {
        text-decoration: none;
        color: black;
        font-weight: bold;
        font-size: 23px;
      }
    }
  }
}

.activeNavbar {
  background-color: white;
  box-shadow: 1px 11px 48px -13px rgb(175 175 175);
  -webkit-box-shadow: 1px 11px 48px -13px rgb(175 175 175);
  -moz-box-shadow: 1px 11px 48px -13px rgb(175 175 175);

  .header_navigation_items {
    color: black;
  }
}

.header_navidate_profile {
  display: flex;
  align-items: center;
  gap: 1em;
  position: relative;

  @media screen and (max-width: $tablets) {
    display: none;
  }

  svg {
    width: 40px;
    cursor: pointer;
  }
}

.signOut {
  position: absolute;
  top: 60px;
  right: 0;
  width: 200px;
  background-color: white;
  display: flex;
  justify-content: center;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 1px 11px 48px -13px rgb(175 175 175);
  -webkit-box-shadow: 1px 11px 48px -13px rgb(175 175 175);
  -moz-box-shadow: 1px 11px 48px -13px rgb(175 175 175);
  cursor: pointer;
}