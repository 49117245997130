// colors
$primary: #69A831;
$primary_dark: #2E3B21;
$grey: #F4F4F4;
$gray: #77878B;
$gray_dark: #373E40;
$blue_green: #30A68F;
$facebook: #2f55a4;
$red: #EA4335;

// Media query
$laptop: 1300px;
$laptop_medium: 1200px;
$laptop_small: 1000px;
$tablets: 900px;
$tablet_small: 750px;
$mobile: 600px;

// Font size
$xxl: 60px;
$xl: 36px;
$lg: 24px;
$md: 20px;
$sm: 18px;
$xs: 15px;

$radius: 4px;