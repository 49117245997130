@import '../variables';

.designAndConstruction {
  img {
    width: 100%;
  }
}
.designAndConstruction_content {
  margin: 2em 0;
}
.designAndConstruction_images, .designAndConstruction_features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2em;

  @media screen and (max-width: $mobile) {
      grid-template-columns: 1fr;
  }
}
.designAndConstruction_video {
  iframe {
    width: 100%;
    height: 400px;
  }
}

.designAndConstruction_steps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1em;

  @media screen and (max-width: $laptop_small) and (min-width: $tablets) {
    grid-template-columns: 1fr 1fr;
  }

  @media screen and (max-width: $mobile) {
    grid-template-columns: 1fr 1fr;
  }
}