@import '../variables';

.modalTypeUser {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  padding: 2.5em 5em;

  @media screen and (max-width: $laptop_medium) {
    padding: 2em;
    width: 70vw;
  }

  @media only screen and (min-width:550px) and (max-width: $tablets) {
    padding: 2em;
    width: 90vw;
  }

  @media screen and (max-width: $mobile) {
    padding: 2em;
    width: 100vw;
  }
}

.modalTypeUser_btns {
  display: flex;
  gap: 1em;
  margin-top: 1em;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
    align-self: center;
    align-items: center;
  }
}

.modalTypeUser_options {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.modalTypeUser_radios {
  display: flex;
  gap: 1em;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}