.modalRegister {
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.modalRegisters_btns {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-top: 1em;
}

.modalRegisters_map {
  width: 100%;
  height: 300px;
}