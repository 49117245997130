@import '../variables';

.modalLogin {
  display: flex;
  flex-direction: column;
  gap: 2em;
  padding: 2em;
  max-width: 450px;

  @media screen and (max-width: $laptop_medium) {
    width: 50vw;
  }

  @media only screen and (min-width:550px) and (max-width: $tablets) {
    width: 65vw;
  }

  @media screen and (max-width: $mobile) {
    width: 90vw;
    padding: 1em;
  }
}

.modalLogin_btns {
  display: flex;
  flex: 1 1 auto;
  gap: 1em;

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}

.modalLogin_btns_social {
  display: flex;
  flex: 1 1 auto;
  gap: 1em;

  @media only screen and (min-width:550px) and (max-width: $laptop_medium) {
    justify-content: center;
  }

  @media screen and (max-width: $mobile) {
    flex-direction: column;
  }
}

.containerCodeInput {
  text-align: center;
}